<template>
  <div class="d-flex justify-content-center">
    <b-spinner
      type="grow"
      class="bg-success mx-1"
    />
    <b-spinner
      type="grow"
      class="bg-success mx-1"
    />
    <b-spinner
      type="grow"
      class="bg-success mx-1"
    />
  </div>

</template>
<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  name: 'LoandingContent',
  components: {
    BSpinner,

  },
  data() {
    return {}
  },
  mounted() {
    this.$router.push('/inicio')
    setTimeout(() => {
      window.location.reload()
    }, 1300)
  },
}
</script>
